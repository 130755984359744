#splash {
  background-repeat: no-repeat;
  background-size: 100% auto;
  min-height: 100%;
  height: 100%;
}
#splash h4 {
  color: #828282;
  margin-top: 18px;
  user-select: none;
  font-family: Neue Montreal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}
#splash .bottom {
  position: absolute;
  bottom: 150px;
  left: 0;
  width: 100%;
}
/*# sourceMappingURL=src/views/SplashScreen.css.map */